import React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const BlogPage = ({data}) => (
  <Layout>
	<h1 style={{textAlign: 'center'}}>Soon.</h1>
	<SEO title="Blog" />
    {/* <SEO title="Home" />
	<h1>BLOG</h1>
    <h2>Latest Posts</h2>
    {data.allMarkdownRemark.edges.map(post => (
			<div key={ post.node.id }>
				<h3>{post.node.frontmatter.title}</h3>
				<small>Posted by {post.node.frontmatter.author} on {post.node.frontmatter.date}</small>
				<br />

				<Link to={post.node.frontmatter.path}>Read More</Link>
				<br />
				<hr />
			</div>
		))}
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}
  </Layout>
)

// try type query

export const pageQuery = graphql`
	query BlogIndexQuery {
		allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: { frontmatter: { type: { eq: "blog" } } }
		 ) {
			edges {
				node {
					id
					frontmatter {
						path
						title
						author
						date
					}
				}
			}
		}
	}
`

export default BlogPage
